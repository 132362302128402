<div class="content-fluid h-100 d-flex">
  <div class="row w-100 h-100 flex-fill">
    <div class="d-flex flex-column align-items-center">
      <div class="h-100 w-100 d-flex flex-column p-5">
        <div class="imagen1"></div>
        <p class="mt-4">Holi, te estaba esperando<br>¿Cómo te llamas?</p>
        <input class="mt-auto inputPer" type="text" placeholder="Tu nombre" [(ngModel)]="info.nombre">
      </div>

      <button class="boton" (click)="clicBot()">
        Entrar
      </button>
    </div>
  </div>
</div>
