<div class="container-fluid fondo">
  <div class="row d-flex justify-content-center">
    <div class="col logo">
    </div>
  </div>
  <div class="row">
    <div class="col hackerPic"></div>
  </div>
  <div class="row justify-content-center">
    <div class="col-11 col-xxl-5 col-lg-6 col-md-8">
      <div class="pantallaExt position-relative d-flex flex-column align-items-center">
        <div [ngSwitch]="s_exp" class="pantallaInt position-relative d-flex w-100">
          <app-intro1 class="flex-fill" *ngSwitchCase="1" (envInfo)="recInfo($event)"></app-intro1>
          <app-intro2 class="flex-fill" *ngSwitchCase="2" (envInfo)="recInfo2($event)" [nombreUser]="nombre"></app-intro2>
          <app-intro3 class="flex-fill" *ngSwitchCase="3" (envInfo)="recInfo2($event)" [nombreUser]="nombre"></app-intro3>
          <app-malware class="flex-fill" *ngSwitchCase="4" (envInfo)="recInfo2($event)"></app-malware>
          <app-pass class="flex-fill" *ngSwitchCase="5" (envInfo)="recInfo2($event)"></app-pass>
          <app-tarjeta class="flex-fill" *ngSwitchCase="6" (envInfo)="recInfo3($event)" (envInfo2)="recInfo4($event)"></app-tarjeta>
        </div>
        <div class="tarjeta">
          <app-creditcard [nombreCard]="nombreCard" [franq]="franq" [clase]="clase"></app-creditcard>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid negro">
  <div class="row">
    <div class="col-md-7 col-12 p-5">
      <p class="text-center">iLoveHacker.com es una aplicación web creada para simular actividades relacionadas con los hackers. Ninguno de los datos son reales.
        Esta es una página de broma con fines académicos.<br><br>UNIR - 2021</p>
    </div>
    <div class="col-md-5 col-12 imagenH"></div>
  </div>
</div>
