import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-malware',
  templateUrl: './malware.component.html',
  styleUrls: ['./malware.component.css']
})
export class MalwareComponent implements OnInit {


  numero = 1;
  @Output() envInfo = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {

    $(
      function(){
        $( document ).keydown(
          function ( event ) {
            Typer.addText( event );
          }
        );
      }
    );

    let Typer={
      text: null,
      accessCountimer:null,
      index:0,
      speed:3,
      file:"",
      init: function(){
        let accessCountimer = setInterval(function(){Typer.updLstChr();},500);
        $.get(Typer.file,function(){// get the text file
          Typer.text="def solucionesUnir(problema):\n"+
            "\n"+
            "    ordenarPizza()\n"+
            "\n"+
            "    while True:\n"+
            "\n"+
            "        objetivos = planeacion(problema)\n"+
            "\n"+
            "        for meta in objetivos:\n"+
            "            escuchar()\n"+
            "            analizar()\n"+
            "            crear()\n"+
            "\n"+
            "        if revision(objetivos) == 'Wow!':\n"+
            "\n"+
            "            return solucion, felicidad\n"+
            "            break\n"+
            "\n"+
            "        else:\n"+
            "            print('Hmm...no esta suficientemente sorprendente')\n"+
            "            continue\n"+
            "\n"+
            "solucionesUnir(TuProblema)";
        });
      },

      content:function(){
        return $(".console").html();
      },

      write:function(str){
        $(".console").append(str);
        return false;
      },


      addText:function(key){
        if(Typer.text){
          let cont=Typer.content();
          if(cont.substring(cont.length-1,cont.length)=="|")
            $(".console").html($(".console").html().substring(0,cont.length-1));
          if(key.keyCode == 32) {
            key.preventDefault();
            return false;
          }
          if(key.keyCode!=8){
            Typer.index+=Typer.speed;
          }else{
            if(Typer.index>0)
              Typer.index-=Typer.speed;

          }
          let text=$("<div/>").text(Typer.text.substring(0,Typer.index)).html();
          let rtn= new RegExp("\n", "g");
          let rts= new RegExp("\\s", "g");
          let rtt= new RegExp("\\t", "g");
          $(".console").html(text.replace(rtn,"<br/>").replace(rtt,"&nbsp;&nbsp;&nbsp;&nbsp;").replace(rts,"&nbsp;"));

        }

      },

      updLstChr:function(){
        let cont=this.content();
        if(cont.substring(cont.length-1,cont.length)=="|")
          $(".console").html($(".console").html().substring(0,cont.length-1));
        else
          this.write("|");
      }
    }

    Typer.init();
  }

  clicBot(){

    this.envInfo.emit(this.numero)

  }


}
