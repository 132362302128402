<div class="content-fluid h-100 d-flex">
  <div class="row w-100 h-100 flex-fill">
    <div class="d-flex flex-column align-items-center">
      <div class="h-100 w-100 d-flex flex-column pt-5">
        <p class="fs-4">¿{{nombreUser}}, hoy qué te apetece?<br></p>
        <div class="row h-100">
          <a class="col d-flex flex-column justify-content-center align-items-center" (click)="clicBot1()" style="color: #F9AE3B">
            <svg style="height: 80px; margin-bottom: 15px" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512">
              <path fill="currentColor" d="M345.7,219.8c-3.2-3.2-8.3-3.2-11.5,0L285,269c-3.2,3.2-3.2,8.3,0,11.5c8.4,8.4,19.4,12.6,30.4,12.6s22-4.2,30.4-12.6
	C362.5,263.8,362.5,236.5,345.7,219.8z M334.2,269c-8.4,8.4-21,10-31,4.9l35.9-35.9C344.2,248,342.6,260.6,334.2,269z M227,269
	l-49.2-49.2c-3.2-3.2-8.3-3.2-11.5,0c-16.7,16.7-16.7,44,0,60.7c8.4,8.4,19.4,12.6,30.4,12.6s22-4.2,30.4-12.6
	C230.2,277.3,230.2,272.2,227,269z M177.8,269c-8.4-8.4-10-21-4.9-31l35.9,35.9C198.8,279,186.2,277.4,177.8,269z M490,410
	c0.1-0.6,0.2-1.2,0.2-1.8v-60.9c0-4.5-3.6-8.2-8.2-8.2h-62c10.4-21.5,16.6-45.4,17.8-70.7h44.2c4.5,0,8.2-3.6,8.2-8.2v-62.8
	c10.1-3.4,17.4-13,17.4-24.2c0-14.1-11.5-25.5-25.5-25.5s-25.5,11.5-25.5,25.5c0,11.2,7.3,20.8,17.4,24.2v54.6h-36.1
	c-0.7-15.3-3.2-30.3-7.6-44.8c-1.3-4.3-5.9-6.7-10.2-5.4c-4.3,1.3-6.7,5.9-5.4,10.2c4.7,15.6,7.1,31.8,7.1,48.3
	c0,91.4-74.4,165.8-165.8,165.8S90.2,351.8,90.2,260.3S164.6,94.6,256,94.6c36.3,0,70.8,11.7,99,32.8c0.3,0.3,0.7,0.6,1.1,0.8
	c15,11.4,28.1,25.3,38.8,41.6c2.5,3.8,7.5,4.8,11.3,2.4c3.8-2.5,4.8-7.5,2.4-11.3c-7.6-11.6-16.3-22.1-26-31.5h47.4
	c4.5,0,8.2-3.7,8.2-8.2V75.8c10.1-3.4,17.4-13,17.4-24.2c0-14.1-11.5-25.5-25.5-25.5s-25.5,11.5-25.5,25.5
	c0,11.2,7.3,20.8,17.4,24.2v37.2h-58.7c-28.5-20.8-62.8-33-99-34.6V49.8c10.1-3.4,17.4-13,17.4-24.2C281.5,11.5,270.1,0,256,0
	s-25.5,11.5-25.5,25.5c0,11.2,7.3,20.8,17.4,24.2v28.7c-54.6,2.4-103.1,29-134.9,69.4H55.4V84.5c10.1-3.4,17.4-13,17.4-24.2
	c0-14.1-11.5-25.5-25.5-25.5S21.7,46.2,21.7,60.3c0,11.2,7.3,20.8,17.4,24.2V156c0,4.5,3.7,8.2,8.2,8.2h54.2
	c-17.5,27.9-27.6,60.9-27.6,96.2c0,15.2,1.9,29.9,5.4,44H29.9c-4.5,0-8.2,3.6-8.2,8.2v54.1c-10.1,3.4-17.4,13-17.4,24.2
	c0,14.1,11.5,25.5,25.5,25.5s25.5-11.5,25.5-25.5c0-11.2-7.3-20.8-17.4-24.2v-45.9h46.2c18.3,51.9,59.5,93.2,111.5,111.5v46.2h-54.6
	c-3.4-10.1-13-17.4-24.2-17.4c-14.1,0-25.5,11.5-25.5,25.5s11.5,25.5,25.5,25.5c11.2,0,20.8-7.3,24.2-17.4h62.8
	c4.5,0,8.2-3.6,8.2-8.2V437c14.1,3.5,28.8,5.4,44,5.4c28.2,0,55-6.5,78.8-18v37.8c-10.1,3.4-17.4,13-17.4,24.2
	c0,14.1,11.5,25.5,25.5,25.5s25.5-11.5,25.5-25.5c0-11.2-7.3-20.8-17.4-24.2v-46.5c0-0.1,0-0.2,0-0.2c24.5-15.1,45.2-35.7,60.2-60.2
	c0.5,0.1,1.1,0.2,1.6,0.2H474v52.7c0,0.6,0.1,1.2,0.2,1.8c-10.2,3.4-17.6,13-17.6,24.3c0,14.1,11.5,25.5,25.5,25.5
	s25.5-11.5,25.5-25.5C507.7,423,500.3,413.4,490,410z M472.9,173.4c0-5.1,4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2s-4.1,9.2-9.2,9.2
	C477,182.6,472.9,178.5,472.9,173.4z M420.7,51.6c0-5.1,4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2s-4.1,9.2-9.2,9.2
	C424.8,60.9,420.7,56.7,420.7,51.6z M246.8,25.5c0-5.1,4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2s-4.1,9.2-9.2,9.2S246.8,30.6,246.8,25.5z
	 M47.3,69.6c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2C56.5,65.4,52.4,69.6,47.3,69.6z M39.1,390.8
	c0,5.1-4.1,9.2-9.2,9.2s-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2C35,381.6,39.1,385.7,39.1,390.8z M116.9,495.7c-5.1,0-9.2-4.1-9.2-9.2
	s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2S122,495.7,116.9,495.7z M352.2,486.5c0,5.1-4.1,9.2-9.2,9.2s-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2
	S352.2,481.4,352.2,486.5z M482.1,443.5c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2S487.2,443.5,482.1,443.5z"/>
            </svg>
            Hacer un malware
          </a>
          <a class="col d-flex flex-column justify-content-center align-items-center" (click)="clicBot2()" style="color: #00FFFF">
            <svg style="height: 80px; margin-bottom: 15px" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512">
              <path fill="currentColor" class="st0" d="M83.7,455.9c4.1,0,7.5-3.4,7.5-7.5v-24l20.8,12c1.2,0.7,2.5,1,3.7,1c2.6,0,5.1-1.3,6.5-3.8
	c2.1-3.6,0.8-8.2-2.7-10.3l-20.8-12l20.8-12c3.6-2.1,4.8-6.7,2.7-10.3c-2.1-3.6-6.7-4.8-10.3-2.7l-20.8,12v-24
	c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v24l-20.8-12c-3.6-2.1-8.2-0.8-10.3,2.7c-2.1,3.6-0.8,8.2,2.7,10.3l20.8,12l-20.8,12
	c-3.6,2.1-4.8,6.7-2.7,10.3c1.4,2.4,3.9,3.8,6.5,3.8c1.3,0,2.6-0.3,3.7-1l20.8-12v24C76.2,452.6,79.6,455.9,83.7,455.9L83.7,455.9z
	 M274.9,433.7c1.4,2.4,3.9,3.8,6.5,3.8c1.3,0,2.6-0.3,3.7-1l20.8-12v24c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-24l20.8,12
	c1.2,0.7,2.5,1,3.7,1c2.6,0,5.1-1.3,6.5-3.8c2.1-3.6,0.8-8.2-2.7-10.3l-20.8-12l20.8-12c3.6-2.1,4.8-6.7,2.7-10.3
	c-2.1-3.6-6.7-4.8-10.3-2.7l-20.8,12v-24c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v24l-20.8-12c-3.6-2.1-8.2-0.8-10.3,2.7
	c-2.1,3.6-0.8,8.2,2.7,10.3l20.8,12l-20.8,12C274,425.5,272.8,430.1,274.9,433.7L274.9,433.7z M160,433.7c1.4,2.4,3.9,3.8,6.5,3.8
	c1.3,0,2.6-0.3,3.7-1l20.8-12v24c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-24l20.8,12c1.2,0.7,2.5,1,3.7,1c2.6,0,5.1-1.3,6.5-3.8
	c2.1-3.6,0.8-8.2-2.7-10.3l-20.8-12l20.8-12c3.6-2.1,4.8-6.7,2.7-10.3c-2.1-3.6-6.7-4.8-10.3-2.7l-20.8,12v-24
	c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v24l-20.8-12c-3.6-2.1-8.2-0.8-10.3,2.7c-2.1,3.6-0.8,8.2,2.7,10.3l20.8,12l-20.8,12
	C159.2,425.5,157.9,430.1,160,433.7L160,433.7z M389.7,433.7c1.4,2.4,3.9,3.8,6.5,3.8c1.3,0,2.6-0.3,3.7-1l20.8-12v24
	c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5v-24l20.8,12c1.2,0.7,2.5,1,3.7,1c2.6,0,5.1-1.3,6.5-3.8c2.1-3.6,0.8-8.2-2.7-10.3
	l-20.8-12l20.8-12c3.6-2.1,4.8-6.7,2.7-10.3c-2.1-3.6-6.7-4.8-10.3-2.7l-20.8,12v-24c0-4.1-3.4-7.5-7.5-7.5c-4.1,0-7.5,3.4-7.5,7.5
	v24l-20.8-12c-3.6-2.1-8.2-0.8-10.3,2.7c-2.1,3.6-0.8,8.2,2.7,10.3l20.8,12l-20.8,12C388.9,425.5,387.7,430.1,389.7,433.7
	L389.7,433.7z M480.3,322.1h-89.6v-71.9c0-4.1-3.4-7.5-7.5-7.5c-4.1,0-7.5,3.4-7.5,7.5v71.9h-85.5l-4-35.5
	c3.5-5.6,5.4-12.1,5.4-18.8c0-9.7-4.1-19.2-11.2-25.8c-7.2-6.8-16.6-10.2-26.5-9.5c-17.8,1.1-32.1,15.4-33.2,33.2
	c-0.4,7.4,1.4,14.6,5.3,20.9l-4,35.5h-85.5V197.3c0-4.6,3.8-8.4,8.4-8.4h222.4c4.6,0,8.4,3.8,8.4,8.4v17.9c0,4.1,3.4,7.5,7.5,7.5
	c4.1,0,7.5-3.4,7.5-7.5v-17.9c0-12.9-10.5-23.4-23.4-23.4H366v-44.2c0-29.4-11.4-57-32.2-77.8C313,31.2,285.4,19.8,256,19.8
	c-60.7,0-110,49.4-110,110v44.2h-1.2c-12.9,0-23.4,10.5-23.4,23.4v124.8H31.7C14.2,322.1,0,336.4,0,353.9v106.6
	c0,17.5,14.2,31.7,31.7,31.7h52c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5h-52c-9.2,0-16.7-7.5-16.7-16.7V353.9
	c0-9.2,7.5-16.7,16.7-16.7h448.5c9.2,0,16.7,7.5,16.7,16.7v106.6c0,9.2-7.5,16.7-16.7,16.7H118.7c-4.1,0-7.5,3.4-7.5,7.5
	c0,4.1,3.4,7.5,7.5,7.5h361.5c17.5,0,31.7-14.2,31.7-31.7V353.9C512,336.4,497.8,322.1,480.3,322.1L480.3,322.1z M301.8,173.9h-91.6
	v-44.2c0-25.3,20.5-45.8,45.8-45.8c12.2,0,23.7,4.8,32.4,13.4c8.7,8.7,13.4,20.2,13.4,32.4V173.9z M161,129.8c0-52.4,42.6-95,95-95
	c25.4,0,49.2,9.9,67.2,27.8c17.9,18,27.8,41.8,27.8,67.2v44.2h-34.2v-44.2c0-16.2-6.3-31.5-17.8-43C287.5,75.3,272.2,69,256,69
	c-33.5,0-60.8,27.3-60.8,60.8v44.2H161V129.8z M238.8,278.9c-2.4-3.7-3.5-7.9-3.2-12.3c0.6-10.1,9-18.5,19.1-19.2
	c5.7-0.4,11.2,1.6,15.3,5.5c4.2,3.9,6.4,9.2,6.4,14.9c0,3.9-1.1,7.8-3.2,11c-1.7,2.6-2.4,5.8-2.1,8.9l3.9,34.4h-38l3.9-34.4
	C241.2,284.6,240.5,281.5,238.8,278.9L238.8,278.9z"/>
            </svg>
            Desbloquear contraseña
          </a>
          <a class="col d-flex flex-column justify-content-center align-items-center" (click)="clicBot3()" style="color: #22B373">
            <svg style="height: 80px; margin-bottom: 15px" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512">
              <path fill="currentColor" d="M503.2,184.1L327.8,8.8c-11.7-11.7-30.7-11.7-42.4,0L90.2,204H30c-16.5,0-30,13.5-30,30v248
	c0,16.5,13.5,30,30,30h372c16.5,0,30-13.5,30-30V297.7l71.2-71.2C514.9,214.9,514.9,195.8,503.2,184.1L503.2,184.1z M299.6,22.9
	c3.9-3.9,10.2-3.9,14.1,0L336,45.2L177.2,204h-58.7L299.6,22.9z M412,482c0,5.5-4.5,10-10,10H30c-5.5,0-10-4.5-10-10V234
	c0-5.5,4.5-10,10-10h372c5.5,0,10,4.5,10,10V482z M489.1,212.4l-57,57V234c0-16.5-13.5-30-30-30H279L386.9,96.1l102.2,102.2
	C493,202.2,493,208.5,489.1,212.4L489.1,212.4z M88.2,262c19.9,0,36,16.1,36,36s-16.1,36-36,36s-36-16.1-36-36S68.4,262,88.2,262z
	 M58,402h42.3c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10H58c-5.5,0-10,4.5-10,10C48,397.5,52.5,402,58,402z M191.5,438H58
	c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10h133.5c5.5,0,10-4.5,10-10C201.5,442.5,197.1,438,191.5,438z M374,438h-42.3
	c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10H374c5.5,0,10-4.5,10-10C384,442.5,379.6,438,374,438z M191.5,382h-42.3
	c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10h42.3c5.5,0,10-4.5,10-10C201.5,386.5,197.1,382,191.5,382z M282.8,382h-42.3
	c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10h42.3c5.5,0,10-4.5,10-10C292.8,386.5,288.3,382,282.8,382z M374,382h-42.3
	c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10H374c5.5,0,10-4.5,10-10C384,386.5,379.6,382,374,382z M286,282v32c0,11,9,20,20,20h14v-72
	h-14C295,262,286,270.9,286,282z M374,314v-32c0-11-9-20-20-20h-14v72h14C365.1,334,374,325,374,314z"/>
            </svg>
            Hacer tarjeta de crédito falsa
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
