<div class="content-fluid h-100 d-flex">
  <div class="row w-100 h-100 flex-fill">
    <div class="d-flex flex-column align-items-center">
      <div class="h-100 w-100 d-flex flex-column pt-5">
        <div class="imagen1"></div>
        <p class="mt-5 fs-5">¡{{nombreUser}}, lo que vamos a hacer está prohibidísimo!<br><br>Igual quieres continuar, ¿verdad, pillín?</p>
      </div>
      <button class="boton" (click)="clicBot()">
        Sí, continuar
      </button>
    </div>
  </div>
</div>
