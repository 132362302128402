<div class="content-fluid h-100 d-flex">
  <div class="row w-100 h-100 flex-fill">
    <div class="d-flex flex-column align-items-center">

      <div class="h-100 w-100 d-flex flex-column justify-content-center">
        <p class="fs-1 password" *ngIf="ocultar"></p>
        <p class="" *ngIf="ocultar">Presiona cualquier tecla para hackear</p>
        <p class="fs-4 acceso hidden parpadeo">Acceso correcto</p>
      </div>
      <button class="boton" (click)="empezar()" *ngIf="ocultar2">
        Desencriptar
      </button>
      <button class="boton volver" [hidden]="ocultar2" (click)="clicBot()">
        Volver
      </button>
    </div>
  </div>
</div>
