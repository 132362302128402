<div class="content-fluid h-100 d-flex">
  <div class="row w-100 h-100 flex-fill">
    <div class="d-flex flex-column align-items-center">
      <div id="console" class="h-100 w-100 d-flex flex-column mb-2 console">
        <div class="placeholdercode">Escribe aqui...</div>
      </div>
      <button class="boton" (click)="clicBot()">
        Volver
      </button>
    </div>
  </div>
</div>
